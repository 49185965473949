<template>
    <!--
    <div>
        Hola desde dispensador
        {{id}}
    </div>-->
    <div v-if="load">
        <v-container>
            <v-row v-for="dispenser in dispensers" :key="dispenser.id_dispenser">
                <v-col cols="12">
                    <div class="text-center">
                        <div class="text-h5">
                            {{dispenser.name}}
                        </div>
                        <span v-if="dispenser.status == 0">
                            <v-btn 
                                @click="getDispenser"
                                small
                                fab
                                outlined
                                class="mb-3 mt-3"
                                elevation="5"
                                color="green"
                            >
                                <v-icon>mdi-update</v-icon>
                            </v-btn><br>
                            <span class="red--text">Sin alimento</span>
                            <v-text-field
                                class="mt-0 pt-0"
                                single-line
                                type="number"
                                value="0"
                                disabled
                            ></v-text-field>
                            <v-btn
                                depressed
                                class="mr-2"
                                disabled
                            >
                                <v-icon>mdi-food-drumstick</v-icon>
                                Alimentar
                            </v-btn>
                        </span>
                        <span v-else-if="dispenser.status == 1 && dispenser.network == 'Conectado'">
                            <v-btn 
                                @click="getDispenser"
                                small
                                fab
                                outlined
                                class="mb-3 mt-3"
                                elevation="5"
                                color="green"
                            >
                                <v-icon>mdi-update</v-icon>
                            </v-btn><br>
                            <span class="green--text">Con alimento</span>
                            <v-text-field
                                v-model="cantidad"
                                class="mt-0 pt-0"
                                single-line
                                type="number"
                                v-on:keyup.enter="setAlimentar()"
                            ></v-text-field>
                            <v-btn
                                dark
                                class="mr-2 botones"
                                @click="setAlimentar()"
                            >
                                <v-icon>mdi-food-drumstick</v-icon>
                                Alimentar
                            </v-btn>
                        </span>
                        <span v-else-if="dispenser.status == 1 && dispenser.network == 'Desconectado'">
                            <v-btn 
                                @click="getDispenser"
                                small
                                fab
                                outlined
                                class="mb-3 mt-3"
                                elevation="5"
                                color="green"
                            >
                                <v-icon>mdi-update</v-icon>
                            </v-btn><br>
                            <span class="green--text">Con alimento</span>
                            <v-text-field
                                class="mt-0 pt-0"
                                single-line
                                type="number"
                                value="0"
                                disabled
                            ></v-text-field>
                            <v-btn
                                depressed
                                class="mr-2"
                                disabled
                            >
                                <v-icon>mdi-food-drumstick</v-icon>
                                Alimentar
                            </v-btn>
                        </span>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div v-if="alimentar" class="text-center">
                        Alimentando...
                        <v-img
                            src="../assets/dog.png"
                            max-height="256px"
                            :aspect-ratio="1"
                            contain
                        >
                        </v-img>
                    </div>
                    <div v-if="alimentar_end" class="text-center">
                        Ya se alimentó...
                        <v-img
                            src="../assets/dog-happy.png"
                            max-height="256px"
                            :aspect-ratio="1"
                            contain
                        >
                        </v-img>
                    </div>
                </v-col>
            </v-row>
            <FlashMessage></FlashMessage>
        </v-container>
    </div>
</template>
<script>
import axios  from 'axios'
export default {
    name: 'Dispenser',
    data(){
        return{
            load: true,
            token: this.$route.params.id,
            cantidad: 1,
            dispensers: [],
            alimentar: false,
            alimentar_end: false,
            flag: false,
            intervalo: null,
            dispenser: {
                id_dispenser: null,
                name: '',
                status: '',
                feed:'',
                network: ''
            }
        }
    },
    created() {
        this.getDispenser()
        this.validUser()
        setInterval(() => {
            this.update()
        }, 1000)
    },
    methods: {
        getDispenser(){
            this.dispensers.splice(0, this.dispensers.length)
            this.alimentar = false
            this.alimentar_end = false
            let data = {
                'opcion': '5',
                'token': this.token,
                'username': localStorage.getItem('username'),
                'jwt_token': localStorage.getItem('token')
            }
            axios.post(this.$pathAPI, data)
                .then((response) => {
                    if(response.status == 200){
                        let datos = response.data
                        if(datos.length){
                            this.dispensers = this.dispensers.concat(datos)
                            this.getNetStatus()
                            if(localStorage.getItem('portion') != null){
                                this.cantidad = localStorage.getItem('portion')
                            }
                        }
                    }
                })
                .catch( (e) => {
                    console.log(e)
                    this.flashMessage.error({message: 'Error en la red!', x: 0, y: 70, time: 1000});
                })
        },
        update(){
            this.dispensers.forEach(token => {
                this.getNetStatus(token['token'])
                this.getStatus(token['token'])
            }); 
        },
        getNetStatus(){
            axios.post('https://serverpets.ine4celectronics.com/checkNet.php', {'opcion': '1', 'token': this.token})
                .then((response) => {
                    if(response.status == 200){
                        let datos = response.data
                        this.dispensers.forEach(dispenser => {
                            if(dispenser['token'] == this.token){
                                dispenser['network'] = datos["mensaje"]
                            }
                        });
                        this.validConn()
                    }
                })
                .catch( (e) => {
                    console.log(e)
                    this.flashMessage.error({message: 'Error en la red!', x: 0, y: 70, time: 1000});
                })
        },
        getStatus(_token){
            axios.post('https://serverpets.ine4celectronics.com/checkNet.php', {'opcion': '3', 'token': _token})
                .then((response) => {
                    if(response.status == 200){
                        let datos = response.data
                        this.dispensers.forEach(dispenser => {
                            if(dispenser['token'] == _token){
                                dispenser['status'] = datos["mensaje"]
                            }
                        });
                    }
                })
                .catch( (e) => {
                    console.log(e)
                    this.flashMessage.error({message: 'Error en la red!', x: 0, y: 70, time: 1000});
                })
        },
        validConn(){
            if(this.dispensers[0]["network"] == "Desconectado"){
                //this.flashMessage.error({message: 'Comprueba la conexión del dispensador!', time: 1000});
            }
        },
        validAlimentar(){
            //console.log('Se envia peticion...')
            let data = {
                'opcion': '6',
                'token': this.token,
                'username': localStorage.getItem('username'),
                'jwt_token': localStorage.getItem('token')
            }
            axios.post(this.$pathAPI, data)
                .then((response) => {
                    if(response.status == 200){
                        let datos = response.data
                        if(datos["mensaje"] == "Ok"){
                            this.alimentar = false
                            this.alimentar_end = true
                            this.flag = true
                            setTimeout(() => {
                                this.alimentar_end  =false
                                this.flag = false
                            }, 3000);
                        }else if(datos["mensaje"] == "No se alimenta"){
                            this.flag = false
                        }
                    }
                })
                .catch( (e) => {
                    console.log(e)
                    this.flashMessage.error({message: 'Error en la red!', x: 0, y: 70, time: 1000});
                })
        },
        setAlimentar(){
            this.alimentar_end = false
            this.flag = false
            let data = {
                'opcion': '4',
                'feed': '1',
                'portion': this.cantidad.toString(),
                'token': this.token,
                'username': localStorage.getItem('username'),
                'jwt_token': localStorage.getItem('token')
            }
            //console.log(data)
            axios.post(this.$pathAPI, data)
                .then((response) => {
                    if(response.status == 200){
                        let datos = response.data
                        if(datos["mensaje"] == "Error al alimentar"){
                            this.alimentar = false
                        }else if(datos["mensaje"] == "Alimentando"){
                            this.alimentar = true
                            this.intervalo = setInterval(() => {
                                this.validAlimentar()
                                if(this.flag == true){
                                    clearInterval(this.intervalo)
                                }
                            }, 2000)
                            localStorage.setItem('portion', this.cantidad)
                        }
                    }
                })
                .catch( (e) => {
                    console.log(e)
                    this.flashMessage.error({message: 'Error en la red!', x: 0, y: 70, time: 1000});
                })
        },
        validUser(){
            let data = {
                'opcion': '7',
                'username': localStorage.getItem('username'),
                'jwt_token': localStorage.getItem('token')
            }
            axios.post(this.$pathAPI, data)
            .then((response) => {
                if(response.status == 200){
                    let datos = response.data
                    if(datos["mensaje"] == "Usuario no autorizado"){
                        localStorage.removeItem('token')
                        localStorage.removeItem('username')
                        localStorage.clear()
                        window.location.reload();
                        this.$router.push('/');
                    }else{
                        this.load = true
                    }
                }
            })
            .catch( (e) => {
                console.log(e)
                this.flashMessage.error({message: 'Error en la red!', x: 0, y: 70, time: 1000});
            })
        }
    }
}
</script>
<style scoped>
    .botones{
        background: #005C97;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to bottom, #363795, #005C97);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, #363795, #005C97); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
</style>